import { TimelineOppositeContent } from "@mui/lab";
import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { ThemeProvider, createTheme } from "@mui/material";
import { motion } from "framer-motion";
import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ScrollRestoration } from "react-router-dom";
import styled from "styled-components";
import GlobalFooter from "../components/GlobalFooter";
import GlobalNavBar from "../components/GlobalNavBar";
import profile_img from "../img/home-profile2.jpg";
import { home } from "./text";

const black = "#000000";
const theme = createTheme({
  palette: {
    primary: {
      main: black,
      contrastText: "#ffffff",
    },
  },
});

const ResponsiveTimeline = styled(Timeline)`
  @media (min-width: 990px) {
     {
      width: 75%;
    }
  }
`;

const Home = () => {
  const ease = [0.08, 0.37, 0.45, 0.89];

  useEffect(() => {
    if (typeof window?.MathJax !== "undefined") {
      window.MathJax.typeset();
    }
  }, []);

  return (
    <>
      <GlobalNavBar />
      <Container className="py-4 mt-3">
        <Row className="mb-5">
          <Col md={6}>
            <motion.div
              variants={{
                hidden: { opacity: 0, y: -20 },
                visible: {
                  opacity: 1,
                  y: 0,
                  transition: { delay: 0.5, duration: 1, ease },
                },
              }}
            >
              <motion.img
                whileHover={{ scale: 1.01 }}
                src={profile_img}
                className="d-block w-100 rounded"
              />
            </motion.div>
          </Col>
          <Col md={6}>
            {home}
          </Col>
        </Row>

        {/* NEWS TIMELINE SECTION */}
        <h3 className="fw-bold">News</h3>
        <ThemeProvider theme={theme}>
          <ResponsiveTimeline>
          <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="primary" />
                <TimelineConnector sx={{ backgroundColor: black }} />
              </TimelineSeparator>
              <TimelineOppositeContent
                sx={{ flex: 0, padding: 0 }}
              ></TimelineOppositeContent>
              <TimelineContent>
                <span className="fw-bold">(01/2025)</span> My paper on Multi-Agent RL got accepted to AAAI 2025. See you in Philadelphia!              </TimelineContent>
            </TimelineItem>
          <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="primary" />
                <TimelineConnector sx={{ backgroundColor: black }} />
              </TimelineSeparator>
              <TimelineOppositeContent
                sx={{ flex: 0, padding: 0 }}
              ></TimelineOppositeContent>
              <TimelineContent>
                <span className="fw-bold">(09/2024)</span> Started my PhD at Georgia Tech in the ACO program.
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="primary" />
                <TimelineConnector sx={{ backgroundColor: black }} />
              </TimelineSeparator>
              <TimelineOppositeContent
                sx={{ flex: 0, padding: 0 }}
              ></TimelineOppositeContent>
              <TimelineContent>
                <span className="fw-bold">(05/2024)</span> Started an internship as an applied scientist intern at Amazon!
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="primary" />
                <TimelineConnector sx={{ backgroundColor: black }} />
              </TimelineSeparator>
              <TimelineOppositeContent
                sx={{ flex: 0, padding: 0 }}
              ></TimelineOppositeContent>
              <TimelineContent>
                <span className="fw-bold">(11/2023)</span> I visited Princeton
                University's mathematics department for a week to work with
                Professor Rose McCarty.
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="primary" />
                <TimelineConnector sx={{ backgroundColor: black }} />
              </TimelineSeparator>
              <TimelineOppositeContent
                sx={{ flex: 0, padding: 0 }}
              ></TimelineOppositeContent>
              <TimelineContent>
                <span className="fw-bold">(09/2023)</span> My paper, Online
                Adaptive Policy Selection in Time-Varying Systems: No-Regret via
                Contractive Perturbations, was accepted at NeurIPS 2023.
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="primary" />
                <TimelineConnector sx={{ backgroundColor: black }} />
              </TimelineSeparator>
              <TimelineOppositeContent
                sx={{ flex: 0, padding: 0 }}
              ></TimelineOppositeContent>
              <TimelineContent>
                <span className="fw-bold">(09/2023)</span> I submitted a paper
                on dynamic algebraic algorithms, which presents a general
                framework for creating fast algorithms, to ITCS 2024.
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="primary" />
                <TimelineConnector sx={{ backgroundColor: black }} />
              </TimelineSeparator>
              <TimelineOppositeContent
                sx={{ flex: 0, padding: 0 }}
              ></TimelineOppositeContent>
              <TimelineContent>
                <span className="fw-bold">(08/2023)</span> I joined Carnegie
                Mellon University as a research assistant in theoretical machine
                learning with Professor Guannan Qu.
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="primary" />
                <TimelineConnector sx={{ backgroundColor: black }} />
              </TimelineSeparator>
              <TimelineOppositeContent
                sx={{ flex: 0, padding: 0 }}
              ></TimelineOppositeContent>
              <TimelineContent>
                <span className="fw-bold">(06/2023)</span> I won the Housner
                Discovery Fund Award at Caltech (Prize fellowship founded by
                George W. Housner, and awarded to 3 each year to be used for
                attending conferences).
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="primary" />
                <TimelineConnector sx={{ backgroundColor: black }} />
              </TimelineSeparator>
              <TimelineOppositeContent
                sx={{ flex: 0, padding: 0 }}
              ></TimelineOppositeContent>
              <TimelineContent>
                <span className="fw-bold">(04/2023)</span> I was named a Daniels
                Fellow in Theoretical Computer Science (Prize fellowship awarded
                by University of Chicago).
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="primary" />
                <TimelineConnector sx={{ backgroundColor: black }} />
              </TimelineSeparator>
              <TimelineOppositeContent
                sx={{ flex: 0, padding: 0 }}
              ></TimelineOppositeContent>
              <TimelineContent>
                <span className="fw-bold">(04/2021)</span> I was named a Richard
                Brewer fellow at Caltech (Prize fellowship founded by Richard
                Brewer and awarded for outstanding solutions to the Physics 11
                hurdles; About 5 recipients in Caltech each year).
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="primary" />
              </TimelineSeparator>
              <TimelineOppositeContent
                sx={{ flex: 0, padding: 0 }}
              ></TimelineOppositeContent>
              <TimelineContent>
                <span className="fw-bold">(06/2019)</span> I was awarded an
                honorary position the UAE International Physics Olympiad (IPhO)
                team 2019 after winning 1st place at the UAE Physics Olympiad.
                Unfortunately, in 2019, travel restrictions prevented the UAE
                team from being entered in the competition.
              </TimelineContent>
            </TimelineItem>
          </ResponsiveTimeline>
        </ThemeProvider>
      </Container>
      <GlobalFooter bottomSticky={false} />
      <ScrollRestoration />
    </>
  );
};

export default Home;
