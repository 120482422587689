import { motion } from "framer-motion";
import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import GlobalNavBar from "../components/GlobalNavBar";
import curie from "../img/about_me/curie.jpg";
import erdos from "../img/about_me/erdos.png";
import germain from "../img/about_me/germain.jpg";
import mirzakhani from "../img/about_me/mirzakhani.webp";
import noether from "../img/about_me/noether.webp";
import ramanujan from "../img/about_me/ramanujan.png";
import shannon from "../img/about_me/shannon.png";
import turing from "../img/about_me/turing.jpg";
import emile1 from "../img/home-profile.jpg";

import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import GlobalFooter from "../components/GlobalFooter";
import { about_me } from "./text";

const InspirationsContainer = styled.div`
  display: flex;
  justify-content: center;

  & > div {
    width: 100%;
  }
`;

const ImageCaptionLink = styled.a`
  position: relative;
  display: block;
  margin-bottom: 0.25rem;

  & span {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-decoration: none;
    color: white;
    padding: 0.25rem;
    margin-bottom: 0.5rem;
    background-color: rgba(0, 0, 0, 0.7);
    font-size: 1.25rem;
  }

  @media screen and (min-width: 768px) {
    & span {
      opacity: 0;
      transition: opacity 0.5s;
    }

    &:hover span {
      opacity: 0.8;
    }
  }
`;

const PersonImageCaption = ({ href, src, name }) => (
  <ImageCaptionLink href={href}>
    <motion.img
      whileHover={{ scale: 1.01 }}
      src={src}
      className="d-block w-100 rounded"
    />
    <span>{name}</span>
  </ImageCaptionLink>
);

const TextContainer = styled.div`
  background-color: white;
  & p > img {
    border-radius: 0.25rem;
    width: 100%;
    margin-bottom: 1rem;
  }
`;

// const MobileContainer = styled.div`
//   #about-me {
//     display: none;
//     visibility: hidden;
//   }
// `;

const mainContent = (
  <TextContainer className="p-3 rounded">{about_me}</TextContainer>
);

const AboutMe = () => {
  useEffect(() => {
    if (typeof window?.MathJax !== "undefined") {
      window.MathJax.typeset();
    }
  }, []);

  const showCols = useMediaQuery({
    query: "(min-width: 992px)",
  });

  return (
    <motion.article
      initial="hidden"
      animate="visible"
      exit={{ opacity: 0, transition: { duration: 0.5 } }}
      style={{
        position: "relative",
        minHeight: "100vh",
        backgroundColor: "rgb(241, 241, 241)",
      }}
    >
      <>
        <GlobalNavBar />
        <Container fluid={true} className="py-4">
          <Row className="gx-3 gy-5">
            <Col md={8}>{mainContent}</Col>
            <Col md={4}>
              <div className="rounded" style={{ backgroundColor: "white" }}>
                <img className="w-100 rounded-top" alt="" src={emile1} />
                <div
                  className="p-3 rounded-bottom"
                  style={{ backgroundColor: "black", color: "white" }}
                >
                  <h3>Emile Timothy Anand</h3>
                  <p>
                    I'm interested in creating robust learning algorithms,
                    driving meaningful impact, and pushing the frontiers of
                    computational limits.
                  </p>
                </div>
              </div>

              <div
                className="rounded mt-5"
                style={{ backgroundColor: "white" }}
              >
                <h3
                  className="p-3 rounded-top"
                  style={{ backgroundColor: "black", color: "white" }}
                >
                  Inspirations
                </h3>
                <div className="px-3">
                  <p>
                    Richard Feynman once said that “the more you know, the more
                    you know what you don’t know”. I’m truly reminded every day
                    of just how little I really know and how many global
                    problems there are out there that are just waiting to be
                    solved. Yet, my thirst for problem solving and my hunger to
                    understand the unknown is letting me rise to this challenge.
                    You will find below the names and links to profiles of great
                    people whose works and stories serve as an inspiration.
                  </p>
                  <InspirationsContainer>
                    <Row>
                      <Col className="mb-3">
                        <PersonImageCaption
                          href="https://en.wikipedia.org/wiki/Maryam_Mirzakhani"
                          src={mirzakhani}
                          name="Maryam Mirzakhani"
                        />
                        <PersonImageCaption
                          href="https://en.wikipedia.org/wiki/Paul_Erd%C5%91s"
                          src={erdos}
                          name="Paul Erdős"
                        />
                        <PersonImageCaption
                          href="https://en.wikipedia.org/wiki/Emmy_Noether"
                          src={noether}
                          name="Emmy Noether"
                        />
                        <PersonImageCaption
                          href="https://en.wikipedia.org/wiki/Alan_Turing"
                          src={turing}
                          name="Alan Turing"
                        />
                      </Col>
                      <Col className="mb-3">
                        <PersonImageCaption
                          href="https://en.wikipedia.org/wiki/Sophie_Germain"
                          src={germain}
                          name="Sophie Germain"
                        />
                        <PersonImageCaption
                          href="https://en.wikipedia.org/wiki/Srinivasa_Ramanujan"
                          src={ramanujan}
                          name="Srinivasa Ramanujan"
                        />
                        <PersonImageCaption
                          href="https://en.wikipedia.org/wiki/Claude_Shannon"
                          src={shannon}
                          name="Claude Shannon"
                        />
                        <PersonImageCaption
                          href="https://en.wikipedia.org/wiki/Marie_Curie"
                          src={curie}
                          name="Marie Curie"
                        />
                      </Col>
                    </Row>
                  </InspirationsContainer>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <GlobalFooter bottomSticky={false} />
      </>
    </motion.article>
  );
};

export default AboutMe;
