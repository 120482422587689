import { motion } from "framer-motion";

const ParaLink = ({ children, href }) => {
  return (
    <motion.a
      whileHover={{
        textDecorationLine: "underline",
        color: "#FF6347",
      }}
      href={href}
      target="_blank"
      rel="noopener"
    >
      {children}
    </motion.a>
  );
};

export const home = (
  <>
    <p>
      Hello there, my name is Emile! I am a first-year PhD student in Computer
      Science at the{" "}
      <ParaLink href="https://www.gatech.edu/">
        Georgia Institute of Technology
      </ParaLink>
      , in the Algorithms, Combinatorics, Optimization (
      <ParaLink href="https://aco.gatech.edu/">ACO</ParaLink>) program.
      Previously, I graduated from{" "}
      <ParaLink href="https://www.caltech.edu/">Caltech</ParaLink>, with degrees
      in Computer Science and Math in 2023, with the excellent mentorship of my
      advisors, Professors{" "}
      <ParaLink href="https://www.cs.caltech.edu/~umans/">Chris Umans</ParaLink>
      , <ParaLink href="https://adamwierman.com/">Adam Wierman</ParaLink>, and{" "}
      <ParaLink href="https://users.cms.caltech.edu/~klbouman/">
        Katie Bouman
      </ParaLink>
      . During this time, I was also fortunate to spend time as a visiting
      student at{" "}
      <ParaLink href="https://www.cmu.edu/">
        Carnegie Mellon University
      </ParaLink>{" "}
      where I was advised by Professor{" "}
      <ParaLink href="https://www.guannanqu.com/">Guannan Qu</ParaLink>.
    </p>
    <p>
      My research interests lie in machine learning and theoretical computer
      science. I have eclectic interests in deep learning, reinforcement
      learning, online optimization, and gradient flows. I’m also interested in
      problems related to dynamic algebraic algorithms and fine-grained
      complexities. Feel free to connect with me. I welcome any comments or
      questions you might have about my research, and am always eager to discuss
      and share ideas!
    </p>
    <p>
      In the summer of 2024, I interned as an applied scientist at{" "}
      <ParaLink href="https://www.amazon.science/">Amazon Science</ParaLink>,
      and have worked as a machine learning engineer at a number of stealth
      start-ups. When I’m not doing research, I enjoy playing the piano and
      training for duathlons. Trivia: My Erdös number is 2.
    </p>
  </>
);

export const about_me = (
  <>
    <h3 id="about-me">About Me</h3>
    <p>
      My research interests lie in machine learning and theoretical computer
      science. Recently, I’ve been thinking about multiagent reinforcement
      learning, preconditioned Langevin dynamics for contextual bandits, and
      online balanced descent. I’m also interested in aspects of deep learning,
      such as neural tangent kernels and diffusion models.
    </p>
    <p>
      I graduated from{" "}
      <ParaLink href="https://www.caltech.edu/">Caltech</ParaLink> with a BS in
      Computer Science, with a focus in theoretical computer science. I worked
      with Professor{" "}
      <ParaLink href="https://www.cs.caltech.edu/~umans/">Chris Umans</ParaLink>{" "}
      on the pseudorandomness of expander random walks, and with Professor{" "}
      <ParaLink href="https://adamwierman.com/">Adam Wierman</ParaLink> on
      online adaptive learning in unknown environments. Before that, I worked
      with Professor{" "}
      <ParaLink href="https://work.caltech.edu/">Yaser Abu-Mostafa</ParaLink> on
      developing the accuracy-expansion hypothesis for multi-classifier neural
      networks and building deep forecasting models for COVID-19 outbreaks.
    </p>
    <p>
      My academic journey began in physics. I spent much of my time in
      high-school studying problems in physics and doing competitions. In the
      summer of 2020, I had a fulfilling internship at the{" "}
      <ParaLink href="https://labcit.ligo.caltech.edu/~rana/">
        Laser Interferometer Gravitational-Wave Observatory
      </ParaLink>
      , where I worked closely with Professor{" "}
      <ParaLink href="https://scholar.google.com/citations?user=5wGd4NsAAAAJ&amp;hl=en">
        Rana Adhikari
      </ParaLink>{" "}
      to study renormalizable operators for gravitons. In 2019, I was chosen to
      represent the UAE at the International Physics Olympiad (IPhO). That same
      year, I also captained the UAE international debate team in the world
      schools debate championship.
    </p>
    <p>
      When I’m not working on research, I try to use my computer science skills
      for good. I currently serve as the campaign manager of the UN-affiliated
      youth-led NGO{" "}
      <ParaLink href="https://www.thegreenhopefoundation.org/">
        Green Hope Foundation
      </ParaLink>
      , and am actively involved as an AI tutor at the{" "}
      <ParaLink href="https://www.caltechy.org/">Caltech Y</ParaLink> (read more
      about this in my teaching page). Outside of academia, I enjoy playing the
      piano (and more recently, the ukulele), and going out for a run/ride while
      preparing for the occasional duathlon. During my time at Caltech, I ran
      for the cross-country and track team. In 2021, I was proud to cross the
      finish line at Elsinore castle in Denmark to win 2nd place at the Elsinore
      70.3 Ironman.
    </p>
  </>
);
